import { HARD_PATHS } from '../utils/hard-paths';

export const PATHS = {
    ABOUT: {
        path: `/about`,
    },
    PRESS_ROOM: {
        path: '/about/press-room',
    },
    NEWS: {
        path: '/about/news',
    },
    NEWS_PAGE: {
        path: '/about/news/:newsUrl',
    },
    CAREERS: {
        path: 'UNKNOWN PATH FOR NOW USING HARDPATH',
        hardPath: HARD_PATHS.FOOTER.CAREERS,
    },
    PARTNERS_PER_TYPE: {
        path: `/about/partners/:partnerType`,
    },
    PARTNERS: {
        path: `/about/partners`,
    },
    CONTACTS: {
        path: '/contact-audiokinetic',
    },
    BILIBILI: {
        path: 'UNKNOWN PATH FOR NOW USING HARDPATH',
        hardPath: HARD_PATHS.FOOTER.BILIBILI,
    },
    FACEBOOK: {
        path: 'UNKNOWN PATH FOR NOW USING HARDPATH',
        hardPath: HARD_PATHS.FOOTER.FACEBOOK,
    },
    NAVER: {
        path: 'UNKNOWN PATH FOR NOW USING HARDPATH',
        hardPath: HARD_PATHS.FOOTER.NAVER,
    },
    TWITCH: {
        path: 'UNKNOWN PATH FOR NOW USING HARDPATH',
        hardPath: HARD_PATHS.FOOTER.TWITCH,
    },
    TWITTER: {
        path: 'UNKNOWN PATH FOR NOW USING HARDPATH',
        hardPath: HARD_PATHS.FOOTER.TWITTER,
    },
    LINKEDIN: {
        path: 'UNKNOWN PATH FOR NOW USING HARDPATH',
        hardPath: HARD_PATHS.FOOTER.LINKEDIN,
    },
    WECHAT: {
        path: 'UNKNOWN PATH FOR NOW USING HARDPATH',
        hardPath: HARD_PATHS.FOOTER.WECHAT,
    },
    WEIBO: {
        path: 'UNKNOWN PATH FOR NOW USING HARDPATH',
        hardPath: HARD_PATHS.FOOTER.WEIBO,
    },
    YOUTUBE: {
        path: 'UNKNOWN PATH FOR NOW USING HARDPATH',
        hardPath: HARD_PATHS.FOOTER.YOUTUBE,
    },
};
