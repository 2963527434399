import { HARD_PATHS } from '../utils/hard-paths';
const moduleRoot = `/settings`;

export const PATHS = {
    ACCOUNT_SETTINGS: {
        path: `${moduleRoot}/account-settings`,
    },
    ACCOUNT_VERIFICATION: {
        path: `${moduleRoot}/account-verification`,
    },
    ACCOUNT_SETTINGS_OPTIONS: {
        path: `${moduleRoot}/account-settings/:optionId`,
    },
    NEW_CREDIT_CARD_FORM: {
        path: `/store/cart?store_card=1`,
        hardPath: HARD_PATHS.SETTINGS.NEW_CREDIT_CARD_FORM,
    },
};
