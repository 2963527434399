import { Instance, types } from 'mobx-state-tree';

import { UserProfile } from '../user-profile';

import EducationModel from './education-model';
import ExperienceModel from './experience-model';
import PublicationModel from './publication-model';
import WwiseCertificationModel from './wwise-certification-model';

const IsPublicModel = types.model('IsPublicModel', {
    profile: false,
    details: false,
    about: false,
    experiences: false,
    education: false,
    wwise_certifications: false,
    projects: false,
    publications: false,
    languages: false,
});

const LanguageModel = types.snapshotProcessor(
    types.model('LanguageModel', {
        language: types.maybeNull(types.string),
        languageCode: types.maybeNull(types.string),
        languageCodeLabel: types.maybeNull(types.string),
        proficiency: types.maybeNull(types.string),
        proficiencyLabel: types.maybeNull(types.string),
        description: types.maybeNull(types.string),
    }),
    {
        preProcessor(sn: any) {
            return { ...sn };
        },
    }
);

const UserProfileDetails = types.model('UserProfileDetails', {
    company: types.maybeNull(types.union(types.string, types.boolean)),
    companySize: types.maybeNull(types.union(types.string, types.boolean)),
    title: types.maybeNull(types.union(types.string, types.boolean)),
    titleLabel: types.maybeNull(types.union(types.string, types.boolean)),
    titleOther: types.maybeNull(types.union(types.string, types.boolean)),
    countryCode: types.maybeNull(types.union(types.string, types.boolean)),
    countryCodeLabel: types.maybeNull(types.union(types.string, types.boolean)),
});

const UserProfileComponentGroup = types.model('UserProfileComponentGroup', {
    avatarImage: types.maybeNull(types.string),
    bannerImage: types.maybeNull(types.string),
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
});

const CreatorsDirectoryModel = types.model('CreatorsDirectoryModel', {
    companyId: types.maybeNull(types.number),
    companyName: types.maybeNull(types.string),
    wantToBeListed: types.maybeNull(types.boolean),
});

const ImageModel = types.snapshotProcessor(
    types.model('ImageModel', {
        base64: types.maybeNull(types.string),
        full: types.maybeNull(types.string),
        cropped: types.maybeNull(types.string),
        crop: types.model('crop', {
            unit: '%',
            x: types.optional(types.number, 10),
            y: types.optional(types.number, 10),
            width: types.optional(types.number, 80),
            height: types.optional(types.number, 80),
        }),
    }),
    {
        preProcessor(sn: any) {
            const timeStamp = new Date();
            return {
                ...sn,
                cropped: sn.cropped
                    ? `${sn.cropped}?${timeStamp.getUTCMinutes()}${timeStamp.getUTCSeconds()}`
                    : null,
            };
        },
    }
);
export type TypeImageModel = Instance<typeof ImageModel>;

export const PublicProfileModel = types.model('PublicProfileModel', {
    about: types.maybeNull(types.string),
    avatar: types.maybeNull(ImageModel),
    banner: types.maybeNull(ImageModel),
    creatorsDirectory: types.array(CreatorsDirectoryModel),
    details: types.snapshotProcessor(UserProfileDetails, {
        preProcessor(sn: any) {
            return { ...sn };
        },
    }),
    experiences: types.array(ExperienceModel),
    education: types.array(EducationModel),
    firstName: types.maybeNull(types.string),
    intro: types.maybeNull(types.string),
    language: types.maybeNull(types.number),
    languages: types.array(LanguageModel),
    lastName: types.maybeNull(types.string),
    preferredLocale: types.maybeNull(types.string),
    projects: types.maybeNull(types.number),
    publications: types.array(PublicationModel),
    website: types.maybeNull(types.string),
    wwise_certifications: types.array(WwiseCertificationModel),
    userId: types.maybeNull(types.number),
});

const ProfileModel = types.compose(
    UserProfile,
    PublicProfileModel,
    types.model('ProfileModel', {
        displayName: types.maybeNull(types.string),
        hasCompany: false,
        hasProjects: false,
        hasSchool: false,
        isAKEmployee: false,
        isEducator: false,
        isVerified: false,
        itemsInCart: 0,
        unreadMessages: 0,
        locale: types.maybeNull(types.string),
        hasSubscriptions: false,
        hasPersonalSubscription: false,
        hasCompanySubscription: false,
        isPublic: types.maybeNull(IsPublicModel),

        // Building grouping structures for individual fields that need to be regrouped
        userProfileComponentGroup: types.maybeNull(UserProfileComponentGroup),
    })
);

export type TypeLanguageModel = Instance<typeof LanguageModel>;
export type TypeProfileModel = Instance<typeof ProfileModel>;
export default ProfileModel;
