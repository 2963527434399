import React, { useEffect } from 'react';
import useEscapeKey from '../../../../custom-hooks/useEscapeKey';

type Props = {
    lockScroller: boolean;
    exitLocker?: () => void;
};
export default function ScrollLocker(props: Props) {
    const { lockScroller, exitLocker } = props;

    // Exit EditMode with ESC key
    const handleKeyup = useEscapeKey(exitLocker as (params: any) => void);

    // Freeze scrollbar
    useEffect(() => {
        document.body.style.overflow = lockScroller ? 'hidden' : 'auto';
        document.body.addEventListener('keyup', handleKeyup);

        return () => {
            document.body.style.overflow = 'auto';
            document.body.removeEventListener('keyup', handleKeyup);
        };
    }, [lockScroller, handleKeyup]);

    return <div />;
}
