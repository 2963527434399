import { Endpoint } from '../api-interfaces';
import { keys, pickBy } from 'lodash';

export const CHECK_EMAIL_TOKEN_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/account/checkEmailToken/',
    formData: (formData) => formData,
    method: 'POST',
};

export const RETRIEVE_USER_ACCOUNT_MESSAGES: Endpoint = {
    api: '/api',
    url: (params: any) => {
        const {
            pagination: { itemsPerPage, pageNumber },
        } = params;
        return `/account/retrieveMessageList/${itemsPerPage}/${pageNumber}`;
    },
    method: 'GET',
};

export const DELETE_USER_ACCOUNT_MESSAGES: Endpoint = {
    api: '/api',
    url: () => '/account/deleteMessages/',
    formData: (data) => data,
    method: 'DELETE',
};

export const USER_ACCOUNT_MESSAGE_INVITATION_RESPONSE: Endpoint = {
    api: '/api',
    url: () => '/account/respondToInvitation/',
    formData: (data) => data,
    method: 'POST',
};

export const SET_USER_ACCOUNT_MESSAGES_READ_FLAG: Endpoint = {
    api: '/api',
    url: () => '/account/readMessages/',
    formData: (data) => data,
    method: 'PUT',
};

export const RETRIEVE_USER_ACCOUNT_MESSAGE_BY_ID: Endpoint = {
    api: '/api',
    url: (params: any) => `/account/retrieveMessageContents/${params.UUIDs.join(',')}`,
    method: 'GET',
};

export const GET_INVOICE_DETAILS_ENDPOINT: Endpoint = {
    api: '/api',
    url: ({ transactionId }) => {
        return `/transaction/${transactionId}/`;
    },
    method: 'GET',
};

export const PUBLIC_PROFILE_ENDPOINT: Endpoint = {
    api: '/api',
    url: ({ userId }) => `/profile/${userId}/`,
};

export const SAVE_SECTION_VISIBILITY_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/account/profile/visibility/',
    method: 'PUT',
    callCancellable: false,
    formData: (data) => data.formData,
};

export const SAVE_USER_PROFILE_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/account/',
    method: 'PATCH',
    formData: (data) => data,
};

export const SAVE_EMAIL_SUBSCRIPTIONS_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/account/profile/emailSubscriptions/',
    method: 'PUT',
    callCancellable: false,
    formData: (data) => data.formData,
};

export const SAVE_EMAIL_RESUBSCRIBE_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/account/profile/emailResubscription/',
    method: 'PUT',
    callCancellable: false,
    formData: (data) => data.formData,
};

export const TRIGGER_USER_RESET_PASSWORD_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/resetPassword/',
    method: 'POST',
    formData: ({ email = '' }) => ({
        email,
    }),
};

export const USER_ACCOUNT_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/account/',
};

export const USER_ACCOUNT_LIGHT_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/account/basic/',
    requiredForMenus: true,
};

export const USER_CHANGE_PRIMARY_EMAIL_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/account/changeEmail/',
    method: 'POST',
    formData: (data) => data,
};

export const CANCEL_SUBSCRIPTION: Endpoint = {
    api: '/api',
    url: ({ subscriptionOwnerId }) => `/account/cancelSubscription/${subscriptionOwnerId}/`,
    method: 'DELETE',
};

export const USER_VALIDATE_PRIMARY_EMAIL_CHANGE_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/account/validateEmailChange/',
    method: 'POST',
    formData: (data) => data,
};

export const USER_CHANGE_PASSWORD_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/change-password/',
    method: 'POST',
    requiredForMenus: true,
    formData: ({
        current_password = '',
        new_password = '',
    }: {
        current_password: string;
        new_password: string;
    }) => ({
        current_password,
        new_password,
    }),
};

export const USER_RESET_NEW_PASSWORD_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/setNewPassword/',
    method: 'POST',
    formData: (data) => data,
};

export const USER_LOGIN_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/login/',
    requiredForMenus: true,
    method: 'POST',
    formData: ({ email = '', password = '' }) => ({
        email,
        password,
    }),
};

export const USER_LOGOUT_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/signOut/',
    requiredForMenus: true,
    method: 'POST',
};

export const USER_PROFILE_DETAILS_LIST_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/profile/info/',
};

export const USER_PROFILE_LANGUAGES_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/languages/',
};

export const USER_RESET_PASSWORD_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/resetPassword/',
    method: 'POST',
    formData: ({ email = '' }) => ({
        email,
    }),
};
export const USER_RESEND_VALIDATION_EMAIL_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/resendValidation/',
    method: 'POST',
    formData: ({ email = '' }) => ({
        email,
    }),
};

export const USER_SIGN_SUBSCRIPTION_TERMS_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/account/signSubscriptionTerms/',
    method: 'POST',
    formData: (data) => data,
};

export const PROJECT_INVITATION_HAS_ACCOUNT: Endpoint = {
    api: '/api',
    url: ({ token }) => `/projectInvitationHasAccount/${token}/`,
};

export const USER_SIGNUP_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/createAccount/',
    requiredForMenus: true,
    method: 'POST',
    formData: (formData) => {
        const {
            email = '',
            password = '',
            Blog,
            Education,
            Releases,
            Strata,
            Wwise,
            projectInvitationToken,
        } = formData;

        return {
            email,
            password,
            projectInvitationToken,
            subscriptions: keys(
                pickBy({ Blog, Education, Releases, Strata, Wwise }, (signupKey) => signupKey)
            ),
        };
    },
};

export const VALIDATE_USER_ACCOUNT_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/account/validate/',
    formData: (formData) => formData,
    method: 'POST',
};

export const SUPPORT_TICKETS_INFO_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/supportTickets/info/',
    formData: (formData) => formData,
};

export const SUPPORT_TICKETS_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/supportTickets/',
    formData: (formData) => formData,
    method: 'POST',
};
