import { getHardPathTarget, cookieLanguage } from '../../utils/utils';

const hardPathTarget = `${getHardPathTarget()}/`;
const userLanguage = cookieLanguage();

export const CANNED_MESSAGE_WWISE_LICENSING = 1;
export const CANNED_MESSAGE_WWISE_UPGRADE = 2;
export const CANNED_MESSAGE_3_NOT_DEFINED = 3;
export const CANNED_MESSAGE_4_NOT_DEFINED = 4;
export const CANNED_MESSAGE_MORE_STRATA_SEATS = 5;
export const CANNED_MESSAGE_STRATA_ACADEMIC = 6;
export const CANNED_MESSAGE_STRATA_PROJECT = 7;

export const SOCIAL_MEDIAS_PATHS = {
    BILIBILI:
        'https://space.bilibili.com/492900204?spm_id_from=333.33.b_73656375726974794f75744c696e6b.1',
    FACEBOOK: {
        'en-US': 'https://www.facebook.com/Audiokinetic/',
        'fr-CA': 'https://www.facebook.com/AudiokineticFr/',
        'ja-JP': 'https://www.facebook.com/audiokinetickk/',
        'ko-KR': 'https://www.facebook.com/Audiokinetic/',
        'zh-CN': 'https://www.facebook.com/Audiokinetic/',
    },
    LINKEDIN: 'https://www.linkedin.com/company/audiokinetic',
    NAVER: 'https://cafe.naver.com/audiokinetic',
    TWITCH: 'https://www.twitch.tv/audiokinetic',
    TWITTER: {
        'en-US': 'https://twitter.com/audiokinetic',
        'fr-CA': 'https://twitter.com/audiokineticFr',
        'ja-JP': 'https://twitter.com/AudiokineticKK',
        'ko-KR': 'https://twitter.com/audiokinetic',
        'zh-CN': 'https://twitter.com/audiokinetic',
    },
    WECHAT: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzIzNzIwNzQ2OQ==&scene=124#wechat_redirect',
    WEIBO: 'https://www.weibo.com/audiokinetic',
    GENERAL_YOUTUBE: 'https://www.youtube.com/c/Audiokinetic',
    YOUTUBE: 'https://www.youtube.com/c/Audiokinetic/videos',
};

export const HARD_PATHS = {
    EMAIL: {
        MARKETING: 'mailto:marketing@audiokinetic.com',
        INFO_GENERAL: 'mailto:info@audiokinetic.com',
        INFO_JP: 'mailto:info@audiokinetic.co.jp',
        INFO_CN: 'mailto:info@audiokinetic.cn',
    },
    HOME: {
        HOME_LOGIN: `${hardPathTarget}login/`,
    },
    HUBSPOT: {
        DISCOUNT_FORM: 'https://share.hsforms.com/1nuV2QSKTQaeswKrfr6xLCw15l47',
        ASSESSMENT_REQUEST: 'https://share.hsforms.com/1dDYYe5VjQROGgHRVTev0YQ15l47',
        HANDSON: `https://info.audiokinetic.com/en/wwise-workshops`,
        ACCELERATED_TRAINING: `https://info.audiokinetic.com/${userLanguage}/wwise-accelerated-training`,
        BECOME_CERTIFIED: `https://info.audiokinetic.com/${userLanguage}/wwise-instructor-program`,
    },
    COMMUNITY: {
        QUESTIONS_ANSWERS: `${hardPathTarget}qa/`,
        QUESTIONS: `${hardPathTarget}qa/questions/`,
        QUESTIONS_UNANSWERED: `${hardPathTarget}qa/unanswered?by=selected`,
        STRATA_QUESTIONS_UNANSWERED: `${hardPathTarget}qa/questions/strata`,
        ASK_A_QUESTION: `${hardPathTarget}qa/ask/`,
        BLOG: `${hardPathTarget}blog/`,
        SUBMIT_BLOG: `https://info.audiokinetic.com/${userLanguage}/submit-your-blog`,
        SUBMIT_VIDEO: `https://info.audiokinetic.com/${userLanguage}/submit-your-video`,
        JOB_SKILLS: `${hardPathTarget}blog/game-audio-job-skills/`,
        CONTRIBUTORS: `${hardPathTarget}blog/contributors/`,
        FIND_CERTIFIED_USER: `${hardPathTarget}directory/?list=users`,
        FIND_COMPANY: `${hardPathTarget}directory/`,
        FIND_SCHOOL: `${hardPathTarget}directory/?list=schools`,
        GET_CERTIFIED: `${hardPathTarget}learn/certifications/`,
        REGISTER_COMPANY: `${hardPathTarget}register-company/company/`,
        SUBSCRIBE_TWITCH: SOCIAL_MEDIAS_PATHS.TWITCH,
        SUBSCRIBE_YOUTUBE: SOCIAL_MEDIAS_PATHS.YOUTUBE,
        SUBSCRIBE_BILIBILI: SOCIAL_MEDIAS_PATHS.BILIBILI,
        CREATORS_DIRECTORY: `${hardPathTarget}directory/`,
        CREATORS_DIRECTORY_FAQ: `${hardPathTarget}directory/faq/`,
        EVENTS: `${hardPathTarget}community/events/`,
        WWISETOUR: `${hardPathTarget}community/wwisetour/`,
        ONE_MINUTE_WWISE: `${hardPathTarget}learning/videos/?playlist=PLXMeprTk4ORPxJ1p1VCNXsNIqRFzbj4I2`,
        WWISE_UP_ON_AIR: `${hardPathTarget}learning/videos/?playlist=PLXMeprTk4ORNDwCc7Bo8qax2MB3i5iXnN`,
        WWISE_INTERACTIVE_MUSIC_SYMPOSIUM: `${hardPathTarget}learn/videos/?playlist=PLXMeprTk4ORPV5EHNrKuhuz1LNQ-DBWhi`,
        GAME_JAMS: `${hardPathTarget}community/game-jams/`,
        REGISTER_GAME_JAM: `${hardPathTarget}register-game-jam/`,
        WWIZARDS_WWITCHES: 'https://www.facebook.com/groups/wwisewwizards/',
        PARTNER_PROGRAM: `${hardPathTarget}register-plugin/`,
        AMBASSADOR_PROGRAM: `${hardPathTarget}directory/ambassador-program/`,
        CREATORS_DIRECTORY_CERTIFIED_SCHOOLS: '/learning/create-school-account',
        CREATORS_DIRECTORY_LICENSED_SCHOOLS: '/learning/create-school-account',
    },
    SOLUTIONS: {
        POWERED_BY_WWISE: `${hardPathTarget}solutions/powered-by-wwise/`,
        CREATIVE_SERVICES: `${hardPathTarget}services/creative-services/`,
        DISCOVER: `${hardPathTarget}discover/`,
        DISCOVER_LBE: `${hardPathTarget}discover/wwise-in-lbe`,
    },
    FOOTER: {
        ABOUT: `${hardPathTarget}about/`,
        NEWS: `${hardPathTarget}about/news/`,
        CAREERS: `${hardPathTarget}about/careers/`,
        FACEBOOK: SOCIAL_MEDIAS_PATHS.FACEBOOK,
        TWITTER: SOCIAL_MEDIAS_PATHS.TWITTER,
        LINKEDIN: SOCIAL_MEDIAS_PATHS.LINKEDIN,
        YOUTUBE: SOCIAL_MEDIAS_PATHS.YOUTUBE,
        TWITCH: SOCIAL_MEDIAS_PATHS.TWITCH,
        BILIBILI: SOCIAL_MEDIAS_PATHS.BILIBILI,
        NAVER: SOCIAL_MEDIAS_PATHS.NAVER,
        WECHAT: SOCIAL_MEDIAS_PATHS.WECHAT,
        WEIBO: SOCIAL_MEDIAS_PATHS.WEIBO,
    },
    GENERAL: {
        REGISTER_PROJECT: `${hardPathTarget}register-project/`,
        CART: `${hardPathTarget}store/cart/`,
        CREATE_BLOG_ACCOUNT: `/settings/account-settings/communications/`,
        FCRM_SEARCH: `${hardPathTarget}search/?term=`,
        FCRM_MY_PROFILE: `${hardPathTarget}account/`,
        FCRM_MY_MESSAGES: `${hardPathTarget}account/messages/`,
        FCRM_MY_DETAILS: `${hardPathTarget}account/details/`,
        FCRM_MY_PROJECTS: `${hardPathTarget}customers/projects/`,
        FCRM_MY_COURSES: `${hardPathTarget}customers/courses/`,
        FCRM_MY_ORGANIZATIONS: `${hardPathTarget}customers/organizations/`,
        FCRM_MY_COMPANY: `${hardPathTarget}customers/organizations/`,
        FCRM_MY_SCHOOL: `${hardPathTarget}customers/my-school/`,
        FCRM_MY_NOTIFICATIONS: `/settings/account-settings/communications/`,
        FCRM_REGISTERED_PROJECTS: `${hardPathTarget}register-project`,
        FCRM_MY_LICENSES: `${hardPathTarget}account/subscriptions/`,
        FCRM_PRICING: `${hardPathTarget}pricing/`,
        FCRM_MY_SUPPORT_TICKETS: `${hardPathTarget}customers/tickets/`,
        FCRM_MY_SUPPORT_TICKETS_NEW: `${hardPathTarget}customers/tickets/new/{{projectMask}}/`,
        FCRM_IN_GAME_CREDITS: `${hardPathTarget}resources/credits/`,
        HOLIDAY_SCHEDULE_MORE_INFO: `https://info.audiokinetic.com/${userLanguage}/holidayschedule2022`,

        OPEN_LAUNCHER: 'wwise-launcher://',
        PROJECTS_LAUNCHER: 'wwise-launcher://Projects',
        DOWNLOAD_LAUNCHER: `${hardPathTarget}download/`,
        DOWNLOAD_LAUNCHER_WINDOWS: `${hardPathTarget}download-launcher/?platform=1`,
        DOWNLOAD_LAUNCHER_MAC: `${hardPathTarget}download-launcher/?platform=2`,

        SUBSCRIBE_GENERAL_YOUTUBE: SOCIAL_MEDIAS_PATHS.GENERAL_YOUTUBE,
        WWISE_TOUR_REGISTER_TO_ATTEND:
            'https://info.audiokinetic.com/wwise-tour-register-to-attend',
        SUBMIT_YOUR_TALK_WWISE_TOUR: 'https://info.audiokinetic.com/submit-your-talk-wwise-tour',
        INTERACTIVE_MUSIC_SYMPOSIUM_REGISTER_TO_ATTEND:
            'https://info.audiokinetic.com/interactive-music-symposium-register-to-attend',
        SUBMIT_YOUR_TALK_INTERACTIVE_MUSIC_SYMPOSIUM:
            'https://info.audiokinetic.com/submit-your-talk-interactive-music-symposium',
        WWWOE_REGISTER_TO_ATTEND: 'https://info.audiokinetic.com/wwwoe-register-to-attend',
        RESOURCES_VIDEOS: `${hardPathTarget}resources/videos/`,
        RESOURCES_SAMPLES: `${hardPathTarget}resources/project-samples/`,
        LINUX_AUTOMOTIVE: 'https://www.automotivelinux.org/',
        STRATA_LICENSE_AGREEMENT: `${hardPathTarget}download/documents/License_Agreements/Audiokinetic_Strata_Click_Through_License_Agreement_2022-09-23.pdf`,
        STRATA_SUBSCRIPTION_AGREEMENT: `${hardPathTarget}download/documents/License_Agreements/Audiokinetic_Strata_Click_Through_Subscription_Agreement_2022-10-03.pdf`,
        COMMUNITY_PARTNER_MARKETING_GUIDELINE: `${hardPathTarget}download/documents/Community_Partner_Marketing_Guidelines_2018-03-09.pdf`,
        ACADEMIC_LICENSE_AGREEMENT: `${hardPathTarget}download/documents/License_Agreements/Academic_License_Agreement_2017-08-21.pdf`,
        INTEGRATION_DEMO: `${hardPathTarget}library/edge/?source=SDK&id=samplecode.html`,
        PRESS_ROOM_IN_GAME_CREDITS: 'https://media.gowwise.com/press-room/in-game-credits.zip',
        PRESS_ROOM_LOGOS: 'https://media.gowwise.com/press-room/logos.zip',
        PRESS_ROOM_PRESS_KIT: 'https://media.gowwise.com/press-room/press-kit.zip',
        PRESS_ROOM_PRODUCT_PAMPHLET: 'https://media.gowwise.com/press-room/product-pamphlet.zip',
        PRESS_ROOM_WWISE_BUMPER_VIDEO:
            'https://media.gowwise.com/press-room/wwise-bumper-video.zip',
    },
    LEGAL: {
        TERMS_OF_USE: `${hardPathTarget}terms-of-use/`,
        PRIVACY_POLICY: `${hardPathTarget}privacy/`,
        USER_ACCOUNT_POLICY: `${hardPathTarget}user-account-policy/`,
        COOKIE_POLICY: `${hardPathTarget}cookie-policy/`,
    },
    HELP: {
        HOLIDAY_SCHEDULE: `https://info.audiokinetic.com/${userLanguage}/holiday-schedule`,
        SUBMIT_SUPPORT_TICKET: `${hardPathTarget}customers/tickets/`,
        SUPPORT_CONTACT_US: `${hardPathTarget}contacts/?subject=Sales`,
        SUPPORT_PAY_2_GO: `${hardPathTarget}services/support/pay2go/`,
        GET_IN_TOUCH: `${hardPathTarget}contacts/`,
        SUPPORT: `${hardPathTarget}services/support/`,
        DOCUMENTATION: `${hardPathTarget}library/`,
        QUESTIONS_ANSWERS: `${hardPathTarget}qa/`,
        REQUEST_FEATURE: `${hardPathTarget}qa/questions/wwise/feature-requests/`,
        REPORT_BUG: `${hardPathTarget}library/wwise_launcher/?source=InstallGuide&id=reporting_bugs`,
        STRATA_DOCUMENTATION: `${hardPathTarget}library/strata/?source=StrataLibrary&id=strata_web_book`,
        STRATA_STEPS: `${hardPathTarget}library/strata/?source=StrataLibrary&id=strata_download_collections`,
        STRATA_COMMUNITY_QNA: `${hardPathTarget}qa/categories/strata`,
        UNITY_INTEGRATION: `${hardPathTarget}library/edge/?source=Unity&id=index.html`,
        UNREAL_INTEGRATION: `${hardPathTarget}library/edge/?source=UE4&id=index.html`,
        WAAPI: `${hardPathTarget}library/edge/?source=SDK&id=waapi.html`,
        WWISE_COMMUNITY_PLUGINS: `${hardPathTarget}library/edge/?source=SDK&id=goingfurther_newplugins.html`,
        WWISE_LAUNCHER: `${hardPathTarget}library/edge/?source=InstallGuide&id=wwise_installation_migration`,
        WWISE_HELP: `${hardPathTarget}library/edge/?source=Help&id=wwise_help`,
        WWISE_WELCOME: `${hardPathTarget}library/edge/?source=Help&id=welcome_to_wwise`,
        WWISE_SDK: `${hardPathTarget}library/edge/?source=SDK&id=index.html`,
        WWISE_ADVENTURE_GAME: `${hardPathTarget}library/wag/?source=WwiseAdventureGame&id=wwise_adventure_game`,
        WWISE_AUDIO_LAB: `${hardPathTarget}library/edge/?source=WwiseAudioLab&id=wwise_audio_lab`,
        WWISE_AUTHORING_API_PACK: `${hardPathTarget}library/edge/?source=SDK&id=waapi_samples.html`,
        WWISE_RELEASE_NOTES: `${hardPathTarget}library/edge/?source=SDK&id=releasenotes.html`,
        PLUGIN_PACKAGING: `${hardPathTarget}library/edge/?source=SDK&id=plugin_packaging.html`,
        UNREAL_SPATIAL_AUDIO: `${hardPathTarget}library/edge/?source=UE4&id=spatialaudio.html`,
        UNITY_SPATIAL_AUDIO: `${hardPathTarget}library/edge/?source=Unity&id=pg_spatialaudio.html`,
        PREVIOUS_VERSIONS: `${hardPathTarget}customers/downloads/previous/`,
        WWISE: `${hardPathTarget}library/edge/`,
        STRATA: `${hardPathTarget}library/strata/`,
        AK_LAUNCHER: `${hardPathTarget}library/wwise_launcher/`,
    },
    STORE: {
        PAY2GO: `${hardPathTarget}services/support/pay2go/`,
        PLUGINS: `${hardPathTarget}wwise/plugins/`,
        CERTIFICATIONS: `${hardPathTarget}learning/certifications/`,
        STRATA: `${hardPathTarget}account/subscriptions/`,
        PURCHASE_CERTIFICATION: `${hardPathTarget}courses/:courseId/?context=exam`,
    },
    WWISE: {
        PRICING: `${hardPathTarget}wwise/pricing/`,
        PRICING_FOR_GAMES: `${hardPathTarget}wwise/pricing/for-games/`,
        PLUGINS: `${hardPathTarget}wwise/plugins/`,
        PLUGINS_INSTALLATION: `${hardPathTarget}wwise/plugins/try-for-free/`,
        SUPPORTED_PLATFORMS: `${hardPathTarget}wwise/supported-platforms/`,
    },
    PRODUCTS: {
        ADD_ONS: `${hardPathTarget}products/wwise-add-ons/`,
        PLUGINS: `${hardPathTarget}wwise/plugins/`,
        WWISE_INTEGRATION: `${hardPathTarget}wwise/wwise-integration/`,
        SUPPORTED_PLATFORMS: `${hardPathTarget}wwise/supported-platforms/`,
        CREATE_ACCOUNT: `${hardPathTarget}sign-up/`,
        REGISTER_COURSE: `${hardPathTarget}register-course`,
        WWISE_SPATIAL_AUDIO: `${hardPathTarget}products/wwise-spatial-audio/`,
        REAPER: 'https://www.reaper.fm/',
        VST_PLUGIN: `${hardPathTarget}library/edge/?source=Help&id=effects`,
        IEM_PLUGIN: 'https://plug-ins.iem.at/',
        STRATA_FUTURE_RELEASE_IMG_PATH:
            'https://media.gowwise.com/images/strata-future-collections/',
        WWISE_WHATS_NEW: 'https://blog.audiokinetic.com/wwise2023.1-whats-new/',
        WWISE_AMBISONIC: `${hardPathTarget}products/ambisonics-in-wwise/`,
    },
    // Intentionally named with a K
    EDUKATION: {
        BECOME_LICENSED_SCHOOL: `${hardPathTarget}register-school/?certified=false`,
        BECOME_CERTIFIED_SCHOOL: `${hardPathTarget}register-school/?certified=true`,
    },
    LEARNING: {
        WWISE_101: `${hardPathTarget}courses/wwise101/`,
        WWISE_201: `${hardPathTarget}courses/wwise201/`,
        WWISE_251: `${hardPathTarget}courses/wwise251/`,
        WWISE_301: `${hardPathTarget}courses/wwise301/`,
        WWISE_101_LESSON_START: `${hardPathTarget}courses/wwise101/?source=wwise101&id=installation`,
        WWISE_101_LESSON_PDF: `${hardPathTarget}lessons/wwise101_en.pdf`,
        WWISE_201_LESSON_START: `${hardPathTarget}courses/wwise201/?source=wwise201&id=installation_of_wwise`,
        WWISE_201_LESSON_PDF: `${hardPathTarget}lessons/wwise201_en.pdf`,
        WWISE_251_LESSON_START: `${hardPathTarget}courses/wwise251/?source=wwise251&id=installation_of_wwise`,
        WWISE_251_LESSON_PDF: `${hardPathTarget}lessons/wwise251_en.pdf`,
        WWISE_301_LESSON_START: `${hardPathTarget}courses/wwise301/?source=wwise301&id=installation_of_wwise`,
        WWISE_301_LESSON_PDF: `${hardPathTarget}lessons/wwise301_en.pdf`,
        VIDEOS: `${hardPathTarget}learning/videos/`,
        AUDIOKINETIC_SCHOOL: `${hardPathTarget}directory/school/2189/`,
    },
    TERMS: {
        EUR_ENGLISH_PDF: `${hardPathTarget}download/documents/AUDIOKINETIC_PO_Terms_and_Conditions_EUR_EN.pdf`,
        US_ENGLISH_PDF: `${hardPathTarget}download/documents/AUDIOKINETIC_PO_Terms_and_Conditions_EN.pdf`,
        CA_ENGLISH_PDF: `${hardPathTarget}download/documents/AUDIOKINETIC_PO_Terms_and_Conditions_CA_EN.pdf`,
        CA_FRENCH_PDF: `${hardPathTarget}download/documents/AUDIOKINETIC_PO_Terms_and_Conditions_CA_FR.pdf`,
        CN_ENGLISH_PDF: `${hardPathTarget}download/documents/AUDIOKINETIC_PO_Terms_and_Conditions_CN_EN.pdf`,
        JP_ENGLISH_PDF: `${hardPathTarget}download/documents/AUDIOKINETIC_PO_Terms_and_Conditions_JP_EN.pdf`,
    },
    SETTINGS: {
        NEW_CREDIT_CARD_FORM: `${hardPathTarget}store/cart/?store_card=1`,
    },
    WWISE_INTEGRATIONS: {
        COMMUNITY_TARGET_1: `${hardPathTarget}library/edge/?source=SDK&id=effectplugin.html`,
        COMMUNITY_TARGET_2: 'https://blog.audiokinetic.com/stepbystep-waapi-example/',
        UNREAL_TARGET_1: `${hardPathTarget}library/edge/?source=UE4&id=index.html`,
        REAWWISE_TARGET_1: `${hardPathTarget}library/reawwise/?source=ReaWwise&id=installing_reawwise_through_reapack`,
        CRYENGINE_TARGET_1: 'http://www.crytek.com/',
        NUENDO_TARGET_1: 'https://www.steinberg.net/',
        NUENDO_TARGET_2: 'https://www.steinberg.net/nuendo/game-audio/',
        O3DE_TARGET_1: 'https://lfprojects.org/',
        GODOT_TARGET_1: 'https://github.com/godotengine/godot/blob/master/AUTHORS.md',
        GODOT_TARGET_2: 'https://sfconservancy.org/',
        SPEECHLESS_TARGET_1: 'https://www.speechless.games/',
        WAAPI_BR_TARGET_1:
            'https://blog.audiokinetic.com/waapi-three-open-source-projects-for-wwise-authoring-api/',
    },
};
