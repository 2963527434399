import React, { useState, FormEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useJakhubTranslation } from '../../../i18n/jakhub-translation';

import { injectStores } from '../../../stores/factories/store-utils';
import { TypeProfileStore } from '../../../stores/profile-store';

import Button from '../../controls/button/button';
import DivButton from '../../common/legos/div-button/div-button';
import TextField from '../../controls/text-field/text-field';
import MiniLoader from '../../loader/mini-loader/mini-loader';

import { EmailOrnament, PasswordOrnament } from '../legos/textfield-ornaments';
import { EMAIL_RULES, MINIMAL_PASSWORD_RULES } from '../legos/field-rules';
import { HEADER_TOOLS } from '../../../constants';

import styles from '../sign-in-up/sign-in-up.module.scss';

const { SIGN_UP, RESET_PASSWORD } = HEADER_TOOLS;

interface Props {
    ProfileStore: TypeProfileStore;
    handleChange(id: string, event: React.ChangeEvent<HTMLInputElement>): void;
    setFormMode(newMode: string): void;
    submitAttempted: boolean;
    setSubmitAttempted: (newState: boolean) => void;
    noSignUpOption?: boolean;
}
function Login(props: Props) {
    const { t, tt } = useJakhubTranslation();
    const {
        ProfileStore: { userLogin, isLoading, userLoginFormData, updateUserLoginFormData },
        handleChange,
        setFormMode,
        setSubmitAttempted,
        noSignUpOption,
    } = props;
    const { email, password } = userLoginFormData;

    const signInLabel = isLoading ? <MiniLoader /> : t('menu-tools.signup-login.btn-login');
    const [passwordVisible, setPasswordVisible] = useState(false);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        updateUserLoginFormData(userLoginFormData);
        if (email !== '' && password !== '') {
            userLogin(userLoginFormData);
        }
    };

    const { control } = useFormContext();

    return (
        <form onSubmit={handleSubmit}>
            <div className={styles.signInUpForm__formItem}>
                <Controller
                    name='email'
                    control={control}
                    defaultValue=''
                    rules={EMAIL_RULES}
                    render={({ field: { onChange, name, ...rest } }) => {
                        return (
                            <TextField
                                label={tt(`menu-tools.signup-login.${name}`)}
                                id={name}
                                size='small'
                                InputProps={EmailOrnament()}
                                onChange={(event) => {
                                    onChange(event.target.value);
                                    handleChange(
                                        name,
                                        event as React.ChangeEvent<HTMLInputElement>
                                    );
                                }}
                                {...rest}
                            />
                        );
                    }}
                />
            </div>
            <div className={styles.signInUpForm__formItem}>
                <Controller
                    name='password'
                    control={control}
                    defaultValue=''
                    rules={MINIMAL_PASSWORD_RULES}
                    render={({ field: { onChange, name, ...rest } }) => {
                        return (
                            <TextField
                                label={tt(`menu-tools.signup-login.${name}`)}
                                id={name}
                                size='small'
                                type={passwordVisible ? 'text' : 'password'}
                                InputProps={PasswordOrnament({
                                    passwordVisible,
                                    setPasswordVisible,
                                })}
                                onChange={(event) => {
                                    onChange(event.target.value);
                                    handleChange(
                                        name,
                                        event as React.ChangeEvent<HTMLInputElement>
                                    );
                                }}
                                {...rest}
                            />
                        );
                    }}
                />
            </div>
            <div className={styles.signInUpForm__formItem}>
                <Button
                    id='login|main-call-to-action'
                    label={signInLabel}
                    onClick={() => setSubmitAttempted(true)}
                    type='submit'
                />
            </div>
            <div className={styles.signInUpForm__formItem}>
                <div className={styles.switchContext}>
                    <div className={styles.switchContextOption}>
                        <DivButton
                            className={`${styles.switchContextOption} ${styles.switchContextOption__button}`}
                            onClick={() => setFormMode(RESET_PASSWORD)}
                        >
                            <span>{t('menu-tools.signup-login.btn-forgotten-password')}</span>
                        </DivButton>
                    </div>
                    {!noSignUpOption && (
                        <div className={styles.switchContextOption}>
                            <DivButton
                                className={`${styles.switchContextOption} ${styles.switchContextOption__button}`}
                                onClick={() => setFormMode(SIGN_UP)}
                            >
                                <span>{t('menu-tools.signup-login.btn-sign-up')}</span>
                            </DivButton>
                        </div>
                    )}
                </div>
            </div>
        </form>
    );
}

export default injectStores(['ApplicationStore', 'ProfileStore'])(Login);
