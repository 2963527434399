import { lazy } from 'react';

import { typedRoutes } from './utils/module-interfaces';
import { PATHS } from './soft-paths/settings';

export const ROUTES = typedRoutes({
    ACCOUNT_SETTINGS: {
        ...PATHS.ACCOUNT_SETTINGS,
        id: 'ACCOUNT_SETTINGS',
        name: 'Account & Settings',
        i18nBase: 'settings:module',
        i18nId: 'settings:module.menu-label',
        displayInMenu: false,
        privateMode: true,
        component: lazy(() => import('../components/modules/account-settings/account-settings')),
    },
    ACCOUNT_VERIFICATION: {
        ...PATHS.ACCOUNT_VERIFICATION,
        id: 'ACCOUNT_VERIFICATION',
        name: 'Account Verification',
        i18nBase: 'settings:module',
        i18nId: 'settings:module.menu-label',
        displayInMenu: false,
        component: lazy(
            () => import('../components/modules/account-settings/account-verification')
        ),
    },
    ACCOUNT_SETTINGS_OPTIONS: {
        ...PATHS.ACCOUNT_SETTINGS_OPTIONS,
        id: 'ACCOUNT_SETTINGS_OPTIONS',
        name: 'Settings',
        i18nBase: 'settings:module',
        i18nId: 'settings:module.menu-label',
        displayInMenu: false,
        privateMode: true,
        component: lazy(() => import('../components/modules/account-settings/account-settings')),
    },
    NEW_CREDIT_CARD_FORM: {
        ...PATHS.NEW_CREDIT_CARD_FORM,
        id: 'NEW_CREDIT_CARD_FORM',
        name: 'NewCreditCardForm',
        i18nBase: 'settings:billing.payment-methods',
        i18nId: 'settings:billing.payment-methods.formLabels.addButton.label',
        displayInMenu: false,
    },
});

export const DEFAULT_ROUTE = ROUTES.ACCOUNT_SETTINGS;
