import { Instance, types } from 'mobx-state-tree';

import { TypedStoreConfig } from '../factories/store';

import AccountModel from './models/account-model';
import BillingModel from './models/billing-model';
import DocumentModel from './models/document-model';
import NotificationsModel from './models/notifications-model';
import ProfileModel from './models/profile-model';
import PublicationsModel from './models/publication-model';
import RelatedEntityModel from './models/related-entity-model';
import SubscriptionsModel from './models/subscription-model';
import TransactionModel from './models/transaction-model';

export const UserAccountModel = TypedStoreConfig({
    modelName: 'UserAccountModel',
    properties: {
        account: types.optional(AccountModel, {}),
        profile: types.optional(ProfileModel, {}),
        relatedEntities: types.array(RelatedEntityModel),
        documents: types.array(DocumentModel),
        notifications: types.maybeNull(NotificationsModel),
        billing: types.array(BillingModel),
        transactionHistory: types.array(TransactionModel),
        subscriptions: types.maybeNull(SubscriptionsModel),
        publications: types.maybeNull(PublicationsModel),
        hasFullProfile: types.maybeNull(types.boolean),
    },
})
    .actions((self) => ({
        getActiveSubscriptions() {
            if (!self.subscriptions) {
                return [];
            }
            const activeSubscriptions = self.subscriptions.purchases.filter((p) => {
                const isActive = p.status == 'Active';
                const isPaid = p.installments ? p.installments.length > 0 : false;
                return isActive && isPaid;
            });
            return activeSubscriptions;
        },
    }))
    .actions((self) => ({
        hasPaidSubscriptions() {
            return self.getActiveSubscriptions().length > 0;
        },
    }));

export type TypeUserAccountModel = Instance<typeof UserAccountModel>;
export default UserAccountModel;
