import { TypedStoreConfig } from '../factories/store';
import { Instance, types } from 'mobx-state-tree';

const EducatorSpaceCardsModel = TypedStoreConfig({
    modelName: 'EducatorSpaceCardsModel',
    properties: {
        id: types.string,
        title: types.string,
        description: types.string,
        icon: types.string,
        ctaLabel: types.string,
        fileURL: types.string,
    },
});

export type TypeEducatorSpaceCardsModel = Instance<typeof EducatorSpaceCardsModel>;

export default EducatorSpaceCardsModel;
