import React, { ReactElement, ComponentProps } from 'react';

import { useJakhubTranslation } from '../../../../i18n/jakhub-translation';
import { useWindowSize } from '../../../../custom-hooks';

import { Resolution as ResolutionEnum } from '../../../../stores/models/model-enums';

import BannerFiller, { Card } from './banner-filler';
import BannerVideo from './banner-video';
import StyledContent from '../../styled-content/styled-content';

import styles from './banner-v2.module.scss';

type Resolution = keyof typeof ResolutionEnum;

const bannersLocation = '/jakhub/images/banners';
const imageType = 'jpg';
const locationLookup = {
    SMALL: `${bannersLocation}/mobile_768x560/IMAGE_ID.${imageType}`,
    XSMALL: `${bannersLocation}/mobile_768x560/IMAGE_ID.${imageType}`,
    MEDIUM: `${bannersLocation}/tablet_1024x440/IMAGE_ID.${imageType}`,
    LARGE: `${bannersLocation}/desktop_3840x320/IMAGE_ID.${imageType}`,
    XLARGE: `${bannersLocation}/desktop_3840x320/IMAGE_ID.${imageType}`,
    XXLARGE: `${bannersLocation}/desktop_3840x320/IMAGE_ID.${imageType}`,
} as Record<Resolution, string>;
const bannerHeightLookup = {
    SMALL: 560,
    XSMALL: 560,
    MEDIUM: 440,
    LARGE: 320,
    XLARGE: 320,
    XXLARGE: 320,
} as Record<Resolution, number>;

export type { Card };

type BannerTextTypes =
    | 'bannerV2Title'
    | 'bannerV2SubTitle'
    | 'bannerV2Description'
    | 'bannerV2LongDescription';
type BannerTextProps = {
    type: BannerTextTypes;
    dictionaryBase: string;
    supportingObject: any;
    lightText: boolean;
    slim: boolean;
};
function BannerText(props: BannerTextProps) {
    const { tt, T } = useJakhubTranslation();
    const { dictionaryBase, supportingObject, type, lightText, slim } = props;

    const dictionaryText: BannerTextKeys = tt(dictionaryBase);
    const extractedText = dictionaryText[type];
    const textStyle = `
        ${styles[type]}
        ${slim ? styles[`${type}_slim`] : ''}
        ${lightText ? styles[`${type}_light`] : ''}
    `;
    return extractedText ? (
        <span className={textStyle}>{T(extractedText, supportingObject)}</span>
    ) : null;
}

type Props = {
    id?: string;
    imageId?: string;
    imageUrl?: string;
    videoUrl?: string;
    cards?: Card[];
    dictionaryBase: string;
    lightText?: boolean;
    noVeil?: boolean;
    slim?: boolean;
    minHeight?: number;
    supportingObject?: any;
    ctasComponent?: ReactElement;
    bottomComponent?: ReactElement;
    stickyBottomComponent?: ReactElement;
} & ComponentProps<'div'>;
type BannerTextKeys = {
    bannerV2SectionTitle: string;
    bannerV2Title: string;
    bannerV2SubTitle: string;
    bannerV2Description: string;
    bannerV2LongDescription: string;
};

type BackGroundDivProps = {
    id?: string;
    imageUrl?: string;
    videoUrl?: string;
    cards?: Card[];
    minHeight?: number;
    noVeil: boolean;
    slim: boolean;
} & ComponentProps<'div'>;
function BackGroundDiv(props: BackGroundDivProps) {
    const { id = '', children, noVeil, slim, imageUrl, videoUrl, cards, minHeight } = props;
    const { resolution } = useWindowSize();

    const slimHeightMultiplier = slim ? 0.75 : 1;
    const minHeightPerResolution = bannerHeightLookup[resolution] * slimHeightMultiplier;

    const minHeightToUse = minHeight ?? minHeightPerResolution;

    if (videoUrl) {
        return (
            <>
                <div id={id} className={styles.bannerVideo}>
                    <BannerVideo videoUrl={videoUrl} controls={false} />
                    {!noVeil && <div className={styles.bannerVideoVeil} />}
                    <div className={styles.bannerVideoChildren}>{children}</div>
                </div>
            </>
        );
    }

    if (cards) return <BannerFiller cards={cards}>{children}</BannerFiller>;

    if (imageUrl) {
        return (
            <div
                id={id}
                style={{
                    backgroundImage: `url(${imageUrl})`,
                    minHeight: `${minHeightToUse ? `${minHeightToUse}px` : null}`,
                }}
                className={styles.bannerHeader}
            >
                {children}
            </div>
        );
    }

    return <div style={{ minHeight: `${minHeight ? `${minHeight}px` : null}` }}>{children}</div>;
}

export default function BannerV2(props: Props) {
    const { tt } = useJakhubTranslation();
    const { resolution, width } = useWindowSize();
    const {
        id,
        children,
        dictionaryBase,
        supportingObject,
        imageId = 'generic',
        imageUrl,
        videoUrl,
        cards,
        ctasComponent,
        bottomComponent,
        stickyBottomComponent,
        lightText = null,
        minHeight,
        noVeil = false,
        slim = false,
    } = props;

    const bannerText: BannerTextKeys = tt(dictionaryBase);

    const textKeys = [
        'bannerV2Title',
        'bannerV2SubTitle',
        'bannerV2Description',
        'bannerV2LongDescription',
    ];
    const { bannerV2Title, bannerV2SubTitle, bannerV2Description } = bannerText;
    const ariaLabel = [bannerV2Title, bannerV2SubTitle, bannerV2Description].join(' ');

    const responsiveImageUrl = locationLookup[resolution].replace('IMAGE_ID', imageId);

    const useLightText =
        !!cards || lightText === true || !!videoUrl || (width < 1024 && lightText !== false);
    return (
        <BackGroundDiv
            id={id}
            imageUrl={imageUrl || responsiveImageUrl}
            videoUrl={videoUrl}
            minHeight={minHeight}
            cards={cards}
            noVeil={noVeil}
            slim={slim}
        >
            <StyledContent contentStyle='HEADER'>
                <StyledContent contentStyle='PADDED'>
                    <span role='img' aria-label={`${ariaLabel}`} style={{ width: 0 }} />
                    <div className={`${styles.bannerBody} ${slim ? styles.bannerBody_slim : ''}`}>
                        <div className={styles.bannerTextContainer}>
                            {textKeys.map((textKey) => (
                                <BannerText
                                    slim={slim}
                                    lightText={useLightText}
                                    key={textKey}
                                    type={textKey as BannerTextTypes}
                                    dictionaryBase={dictionaryBase}
                                    supportingObject={supportingObject}
                                />
                            ))}
                        </div>
                        {ctasComponent ? ctasComponent : null}
                        {children}
                    </div>
                    {bottomComponent ? (
                        <div className={styles.bannerBottom}>{bottomComponent}</div>
                    ) : null}
                </StyledContent>
            </StyledContent>
            {stickyBottomComponent ? (
                <div className={styles.bannerStickyBottom}>{stickyBottomComponent}</div>
            ) : null}
        </BackGroundDiv>
    );
}
