import { Instance, types } from 'mobx-state-tree';

const Platform = types.model('Platform', {
    id: types.union(types.string, types.number),
    platformId: types.union(types.string, types.number),
    platformName: '',
    icon: types.model({
        sprite: types.string,
        smallGrayScale: types.string,
    }),
    position: 0,
    platformTag: '',
    platformGroups: types.maybeNull(types.array(types.string)),
});

export type TypePlatform = Instance<typeof Platform>;

const PlatformModel = types.snapshotProcessor(Platform, {
    preProcessor(sn: TypePlatform) {
        return {
            ...sn,
            id: sn.platformId,
        } as TypePlatform;
    },
});
export type TypePlatformModel = Instance<typeof PlatformModel>;

export default PlatformModel;
