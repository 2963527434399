import { typedRoutes } from './utils/module-interfaces';

import { lazy } from 'react';
import { PATHS } from './soft-paths/strata';

export const ROUTES = typedRoutes({
    STRATA: {
        ...PATHS.STRATA,
        id: 'OVERVIEW',
        i18nBase: 'strata:module',
        i18nId: 'strata:module.menu-label',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/products/strata/strata')),
    },
    OVERVIEW: {
        ...PATHS.OVERVIEW,
        id: 'OVERVIEW',
        i18nBase: 'strata:overview',
        i18nId: 'strata:overview.menu-label',
        component: lazy(() => import('../components/modules/products/strata/strata')),
    },
    SOUND_EFFECTS_COLLECTIONS_ID: {
        ...PATHS.SOUND_EFFECTS_COLLECTIONS_ID,
        id: 'SOUND_EFFECTS_COLLECTIONS_ID',
        i18nBase: 'strata:collections',
        i18nId: 'strata:collections.menu-label',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/store/strata/strata')),
    },
    SOUND_EFFECTS_COLLECTIONS: {
        ...PATHS.SOUND_EFFECTS_COLLECTIONS,
        id: 'SOUND_EFFECTS_COLLECTIONS',
        i18nBase: 'strata:collections',
        i18nId: 'strata:collections.menu-label',
        component: lazy(() => import('../components/modules/store/strata/strata')),
    },
    SOUND_EFFECTS_COLLECTIONS_COLLECTION: {
        ...PATHS.SOUND_EFFECTS_COLLECTIONS_COLLECTION,
        id: 'SOUND_EFFECTS_COLLECTIONS_COLLECTION',
        name: 'Collection detail page',
        i18nBase: 'store:module.strata',
        i18nId: 'store:module.strata.menu-label',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/store/collection/collection')),
    },
    SOUND_EFFECT_COLLECTION_BAD: {
        ...PATHS.SOUND_EFFECTS_COLLECTION_BAD,
        id: 'SOUND_EFFECT_COLLECTION_BAD',
        name: 'Collection detail page',
        i18nId: 'store:module.strata.menu-label',
        redirectTo: PATHS.SOUND_EFFECTS_COLLECTIONS.path,
        displayInMenu: false,
    },
    COLLECTIONS_ID: {
        ...PATHS.COLLECTIONS_ID,
        id: 'COLLECTIONS_ID',
        i18nId: 'strata:collections.menu-label',
        redirectTo: PATHS.SOUND_EFFECTS_COLLECTIONS.path,
        displayInMenu: false,
    },
    COLLECTIONS: {
        ...PATHS.COLLECTIONS,
        id: 'COLLECTIONS',
        i18nId: 'strata:collections.menu-label',
        redirectTo: PATHS.SOUND_EFFECTS_COLLECTIONS.path,
        displayInMenu: false,
    },
    COLLECTION: {
        ...PATHS.COLLECTION,
        id: 'COLLECTION',
        name: 'Collection detail page',
        i18nId: 'store:module.strata.menu-label',
        redirectTo: PATHS.SOUND_EFFECTS_COLLECTIONS.path,
        displayInMenu: false,
    },
    COLLECTION_BAD: {
        ...PATHS.COLLECTION,
        id: 'COLLECTION',
        name: 'Collection detail page',
        i18nId: 'store:module.strata.menu-label',
        redirectTo: PATHS.SOUND_EFFECTS_COLLECTIONS.path,
        displayInMenu: false,
    },
    PRICING: {
        ...PATHS.PRICING,
        id: 'PRICING',
        i18nBase: 'wwise:pricing',
        i18nId: 'wwise:pricing.menu-label',
        component: lazy(() => import('../components/modules/store/strata-pricing/strata-pricing')),
    },
    PRICING_TAB: {
        ...PATHS.PRICING_TAB,
        id: 'PRICING_TAB',
        i18nBase: 'wwise:pricing',
        i18nId: 'wwise:pricing.menu-label',
        component: lazy(() => import('../components/modules/store/strata-pricing/strata-pricing')),
        displayInMenu: false,
    },
    LICENSING: {
        ...PATHS.LICENSING,
        id: 'LICENSING',
        i18nBase: 'wwise:licensing-faq',
        i18nId: 'wwise:licensing-faq.menu-label',
        component: lazy(() => import('../components/modules/general/licensing-faq/licensing-faq')),
    },
    REGISTER_PROJECT: {
        ...PATHS.REGISTER_PROJECT,
        id: 'REGISTER_PROJECT',
        i18nBase: 'wwise:register-project',
        i18nId: 'wwise:register-project.menu-label',
    },
});

export const DEFAULT_ROUTE = ROUTES.STRATA;
