import { useCallback } from 'react';

export default function useEscapeKey(callback: (params?: any) => any) {
    return useCallback(
        (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                callback();
            }
        },
        [callback]
    );
}
