/* eslint-disable react/jsx-props-no-spreading */
import React, { MouseEvent } from 'react';
import MuiButton, { ButtonProps } from '@material-ui/core/Button';
import { isUndefined } from 'lodash';

export type ButtonVariant = 'outlined' | 'contained' | 'text';
export type ButtonColor = 'inherit' | 'primary' | 'secondary' | 'danger' | 'default';

export type Props = {
    label?: React.ReactNode;
    noWrap?: boolean;
} & ButtonProps;

function Button(props: Props) {
    const {
        label,
        variant = 'contained',
        onClick,
        size = 'medium',
        color = 'primary',
        children,
        noWrap = true,
        ...other
    } = props;

    const whiteSpaceWrap = noWrap ? 'nowrap' : undefined;

    return (
        <MuiButton
            {...other}
            variant={variant}
            size={size}
            onClick={(event: MouseEvent<HTMLButtonElement>) => {
                // Button may be wrapped in a MenuLink, in which case we need to let propagation through
                if (!isUndefined(onClick)) {
                    event.stopPropagation();
                    onClick?.(event);
                }
            }}
            color={color}
        >
            {label ? <div style={{ whiteSpace: whiteSpaceWrap }}>{label}</div> : children}
        </MuiButton>
    );
}

export default Button;
