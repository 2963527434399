import React, { useState, useRef, PropsWithChildren } from 'react';

import cx from 'classnames';
import { merge } from 'lodash';
import ReactCrop, { centerCrop, makeAspectCrop, PixelCrop, PercentCrop } from 'react-image-crop';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Switch } from '@material-ui/core';

import { convertBytesTo } from '../../../utils/utils';
import { TypeImageModel } from '../../../stores/user-profile-store/models/profile-model';
import { useJakhubTranslation } from '../../../i18n/jakhub-translation';
import { useDebounce, useWindowSize } from '../../../custom-hooks';
import { CanvasPreview } from '../previewers/canvas-preview';

import Button from '../button/button';

import { UPLOAD_FILE_SIZE_LIMIT, UPLOAD_FILE_TYPES } from '../../../constants';

import 'react-image-crop/dist/ReactCrop.css';
import styles from './image-uploader.module.scss';
import previewStyles from '../previewers/canvas-preview.module.scss';

const isAdvancedUpload = (function () {
    const div = document.createElement('div');
    return (
        ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
        'FormData' in window &&
        'FileReader' in window
    );
})();

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
    return centerCrop(
        makeAspectCrop({ unit: '%', width: 90 }, aspect, mediaWidth, mediaHeight),
        mediaWidth,
        mediaHeight
    );
}

type SelectedFileErrorProps = {
    hasErrors: { fileSize: boolean; fileType: boolean };
    dictionaryBase: string;
} & PropsWithChildren<{}>;
function SelectedFileError(props: SelectedFileErrorProps) {
    const { tt, T } = useJakhubTranslation();
    const { dictionaryBase, hasErrors, children } = props;

    const { uploadErrorFileSize, uploadErrorFileType } = tt(dictionaryBase);
    return (
        <>
            {hasErrors.fileSize || hasErrors.fileType ? (
                <>
                    <span>
                        {hasErrors.fileSize
                            ? T(uploadErrorFileSize, {
                                  AK_VAR_FILE_SIZE_LIMIT: convertBytesTo(UPLOAD_FILE_SIZE_LIMIT),
                              })
                            : null}
                    </span>
                    <span>
                        {hasErrors.fileType
                            ? T(uploadErrorFileType, {
                                  AK_VAR_FILE_TYPE: UPLOAD_FILE_TYPES.join(', '),
                              })
                            : null}
                    </span>
                </>
            ) : (
                children
            )}
        </>
    );
}

/*
    1/1  : Squares
    16/9 : Typical horizontal screen ratio
    12/1 : Our current banner ratio.
           Good for VERY wide Jakhub banner (3840x320) or our user-profile banner ~(1360x125)
*/
export type ImageRatio = '1/1' | '16/9' | '12/1';
type Props = {
    aspectRatio: ImageRatio;
    onChange: (data: any) => void;
    value: TypeImageModel;
};
export default function ImageUploader(props: Props) {
    const { t, tt, T } = useJakhubTranslation();
    const { aspectRatio, onChange, value } = props;
    const dictionaryBase = 'profile:avatar';

    const { isMobile } = useWindowSize();

    const { full, crop, base64 } = { ...value };

    const imgRef = useRef<HTMLImageElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);

    const [serverData, setServerData] = useState({ full, crop, base64 });

    const [imgSrc, setImgSrc] = useState(full);

    const ratio = aspectRatio.split('/');
    const aspect = parseInt(ratio[0], 10) / parseInt(ratio[1], 10);

    const [editingCrop, setEditingCrop] = useState<PercentCrop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

    const [zoomed, setZoomed] = useState(true);
    const [hovering, setHovering] = useState(false);

    const [hasErrors, setHasErrors] = useState({ fileSize: false, fileType: false });

    function onSelectImage(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files) {
            setHasErrors({ fileSize: false, fileType: false });
            if (e.target.files[0].size > UPLOAD_FILE_SIZE_LIMIT) {
                setHasErrors({ ...hasErrors, fileSize: true });
                setTimeout(() => setHasErrors({ fileSize: false, fileType: false }), 6000);
                e.target.value = '';
            }
            const splitFileName = e.target?.files?.[0]?.name?.split('.') || '';
            if (!UPLOAD_FILE_TYPES.includes(splitFileName[splitFileName.length - 1])) {
                setHasErrors({ ...hasErrors, fileType: true });
                setTimeout(() => setHasErrors({ fileSize: false, fileType: false }), 6000);
                e.target.value = '';
            }
            if (e.target.files.length > 0) {
                const reader = new FileReader();
                reader.addEventListener('load', () => {
                    const base64 = reader.result?.toString() || '';
                    setImgSrc(base64);
                    handleChange({ base64, full: null, cropped: null });
                });

                reader.readAsDataURL(e.target.files[0]);
            }
        }
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
            const { width, height } = e.currentTarget;
            const initialCrop = base64
                ? centerAspectCrop(width, height, aspect)
                : (crop as PercentCrop);

            const newPixelCrop = {
                unit: 'px',
                x: (width * initialCrop.x) / 100,
                y: (height * initialCrop.y) / 100,
                width: (width * initialCrop.width) / 100,
                height: (height * initialCrop.height) / 100,
            } as PixelCrop;

            handleChange({ crop: initialCrop });
            setEditingCrop(initialCrop);
            setCompletedCrop(newPixelCrop);
        }
    }

    useDebounce(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                CanvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
            }
        },
        300,
        [completedCrop]
    );

    const handleChange = (data: any) => {
        const updatedData = merge(serverData, data);
        setServerData(updatedData);
        onChange(updatedData);
    };

    const previewScaleClass = zoomed ? previewStyles.preview200 : previewStyles.preview100;
    const boxInputClass = cx(
        styles.boxInput,
        hovering ? styles.fileHover : '',
        !isAdvancedUpload || isMobile ? styles.simple : ''
    );
    const dragNDropClass = cx(
        styles.boxDragNDrop,
        isAdvancedUpload && !isMobile ? '' : styles.displayNone,
        hovering ? styles.fileHover : '',
        (hasErrors.fileSize || hasErrors.fileType) && styles.fileError
    );
    const legacyUploadClass = cx(
        styles.boxButton,
        !isAdvancedUpload || isMobile ? '' : styles.displayNone,
        hasErrors && styles.fileError
    );
    const downloadIconClass = cx(styles.downloadIcon, hovering ? styles.fileHover : '');

    const hover = (e: React.UIEvent, hoverState: 'on' | 'off') => {
        // I have to do this because there is no drag-over pseudo-class in css :-|
        setHovering(hoverState === 'on');
    };

    const errorBoxClass = cx(
        styles.boxError,
        (hasErrors.fileSize || hasErrors.fileType) && styles.fileError
    );

    const { dragNDrop } = tt(`${dictionaryBase}.upload`);

    return (
        <div className={styles.imageUploader}>
            <div className={boxInputClass}>
                <label
                    htmlFor='file'
                    className={styles.activeInputLabel}
                    onDragOver={(e) => hover(e, 'on')}
                    onDragEnter={(e) => hover(e, 'on')}
                    onDragEnd={(e) => hover(e, 'off')}
                    onDragLeave={(e) => hover(e, 'off')}
                    onDrop={(e) => hover(e, 'off')}
                >
                    <input
                        className={styles.hiddenInputField}
                        type='file'
                        accept={UPLOAD_FILE_TYPES.map((t) => `.${t}`).join(',')}
                        ref={fileInputRef}
                        onChange={onSelectImage}
                    />
                </label>
                <div className={dragNDropClass}>
                    <div className={downloadIconClass}>
                        <FileDownloadOutlinedIcon />
                    </div>
                    <label htmlFor='file'>
                        <SelectedFileError
                            hasErrors={hasErrors}
                            dictionaryBase={`${dictionaryBase}.upload`}
                        >
                            {T(dragNDrop)}
                        </SelectedFileError>
                    </label>
                </div>
                <div className={legacyUploadClass}>
                    <SelectedFileError
                        hasErrors={hasErrors}
                        dictionaryBase={`${dictionaryBase}.upload`}
                    >
                        <Button
                            id='UserProfile|Avatar|UploadButton'
                            label={t(`${dictionaryBase}.upload.button`)}
                            onClick={() => fileInputRef.current?.click()}
                            variant='contained'
                            size='small'
                        />
                    </SelectedFileError>
                </div>
                <div
                    className={errorBoxClass}
                    onClick={() => setHasErrors({ fileSize: false, fileType: false })}
                >
                    <div className={styles.dismiss}>
                        <CloseOutlinedIcon />
                    </div>
                </div>
            </div>
            <div>
                {imgSrc ? (
                    <div className={styles.images}>
                        <div className={styles.original}>
                            <div className={styles.imageTitle}>
                                {t(`${dictionaryBase}.upload.imageOriginal`)}
                            </div>
                            <ReactCrop
                                className={styles.CropperWrapper}
                                crop={editingCrop}
                                onChange={(pixelCrop, percentCrop) => {
                                    handleChange({ crop: percentCrop, cropped: null });
                                    // Setting percentage crop to store on BE
                                    setEditingCrop(percentCrop);
                                    // Setting pixel crop for preview
                                    setCompletedCrop(pixelCrop);
                                }}
                                onComplete={(c) => setCompletedCrop(c)}
                                aspect={aspect}
                                minHeight={40}
                                circularCrop
                            >
                                <img
                                    ref={imgRef}
                                    alt={tt(`${dictionaryBase}.upload.imageToCrop`)}
                                    src={imgSrc + ''}
                                    onLoad={onImageLoad}
                                />
                            </ReactCrop>
                        </div>
                        <div className={styles.preview}>
                            <div className={styles.imageTitle}>
                                {t(`${dictionaryBase}.upload.imagePreview`)}
                            </div>
                            <div>
                                {t(`${dictionaryBase}.upload.imageActual`)}
                                <Switch
                                    color='default'
                                    checked={zoomed}
                                    onChange={() => setZoomed(!zoomed)}
                                />
                                {t(`${dictionaryBase}.upload.imageZoomed`)}
                            </div>
                            <div className={previewScaleClass}>
                                <canvas ref={previewCanvasRef} />
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
}
