import { Endpoint } from '../api-interfaces';

export const STRATA_ADDITIONAL_INFO_STATIC_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/strata-additional-info/',
};

export const SPECIAL_BANNER_MESSAGE_INFO_STATIC_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/special-banner-message/',
};

export const POWERED_BY_WWISE_GAMES_STATIC_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/powered-by-wwise-games/',
};

export const SUPPORTED_PLATFORMS_STATIC_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/platformIntegrationPluginGrid/',
};

export const SUBSCRIPTION_INFO_STATIC_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/email-subscriptions-info/',
};

export const TEMPORARY_USER_PROJECT_LIST_STATIC_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/contacts/projectInfo/',
};

export const WWISE_OVERVIEW_HEADER_CTAS: Endpoint = {
    api: '/api',
    url: () => '/wwise-overview-header-ctas/',
};

export const EDUCATOR_SPACE_CARDS: Endpoint = {
    api: '/api',
    url: () => '/educator-space-cards/',
};

export const COMPONENT_ENABLER_STATIC_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/component-enabler/',
};
