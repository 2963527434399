export type FormSubmitDataStructure = {
    id: string;
    form_path: string;
    data_path: string;
    fieldType: string;
    validationId?: string;
    labelId?: string;
    hidden?: boolean;
};

const unknownUserInfo = [
    {
        id: 'unknown_user_firstname',
        form_path: 'firstName',
        data_path: 'firstName',
        fieldType: 'TextField',
        validationId: 'firstName',
    },
    {
        id: 'unknown_user_lastname',
        form_path: 'lastName',
        data_path: 'lastName',
        fieldType: 'TextField',
        validationId: 'lastName',
    },
    {
        id: 'unknown_user_email',
        form_path: 'email',
        data_path: 'email',
        fieldType: 'TextField',
        validationId: 'email',
    },
];
const unknownUserInfoInitData = {
    firstName: '',
    lastName: '',
    email: '',
};

const loggedInUserInfo = [
    {
        id: 'user_email',
        form_path: 'email',
        data_path: 'userAccount.account.emails.primary',
        fieldType: 'SimpleDiv',
        labelId: 'general:contact-form.formLabels.user_email.label',
    },
    {
        id: 'user_email_copy',
        form_path: 'sendCopyToRequester',
        data_path: 'sendCopyToRequester',
        fieldType: 'CheckBox',
        validationId: '',
    },
];
const loggedInUserInfoInitData = {
    sendCopyToRequester: false,
};

const projectInfo = [
    {
        id: 'selected_project',
        form_path: 'projectId',
        data_path: 'projectId',
        fieldType: 'TextFieldDropDown',
    },
    {
        id: 'project_member_email_copy',
        form_path: 'sendCopyToProjectMembers',
        data_path: 'sendCopyToProjectMembers',
        fieldType: 'CheckBox',
    },
];
const projectInfoInitData = {
    projectId: '',
    sendCopyToProjectMembers: false,
};

const inquiryInfo = [
    {
        id: 'inquiry_subject',
        form_path: 'subject',
        data_path: 'selectedInquirySubject',
        fieldType: 'TextFieldDropDown',
        validationId: 'subject',
    },
    {
        id: 'inquiry_subject_other',
        form_path: 'subjectOther',
        data_path: 'selectedInquirySubjectOther',
        fieldType: 'TextField',
        validationId: 'subjectOther',
    },
    {
        id: 'inquiry_description',
        form_path: 'message',
        data_path: 'inquiryDescription',
        fieldType: 'MultiLineTextField',
        validationId: 'description',
    },
];

const inquiryInfoEducatorSpace = [
    {
        id: 'inquiry_teaching_facility',
        form_path: 'facility',
        data_path: 'facility',
        fieldType: 'TextField',
        validationId: 'subject',
    },
    {
        id: 'inquiry_subject',
        form_path: 'subject',
        data_path: 'selectedInquirySubject',
        fieldType: 'TextField',
        validationId: 'subject',
        hidden: true,
    },
    {
        id: 'inquiry_description',
        form_path: 'message',
        data_path: 'inquiryDescription',
        fieldType: 'MultiLineTextField',
        validationId: 'description',
    },
];

const inquiryInfoEducatorSpaceInitData = {
    facility: '',
    selectedInquirySubject: 'education',
    inquiryDescription: '',
};

const inquiryInfoInitData = {
    selectedInquirySubject: '',
    selectedInquirySubjectOther: '',
    inquiryDescription: '',
};

const termsAndConditionsInfo = [
    {
        id: 'accept_terms_and_conditions',
        form_path: '',
        data_path: 'unknownUserAcceptsTerms',
        labelId: 'general:contact-form.formLabels.accept_terms_and_conditions.label',
        fieldType: 'CheckBox',
    },
];
const termsAndConditionsData = {
    unknownUserAcceptsTerms: false,
};

export default {
    unknownUserInfo,
    unknownUserInfoInitData,

    loggedInUserInfo,
    loggedInUserInfoInitData,

    projectInfo,
    projectInfoInitData,

    inquiryInfoEducatorSpace,
    inquiryInfoEducatorSpaceInitData,
    inquiryInfo,
    inquiryInfoInitData,

    termsAndConditionsInfo,
    termsAndConditionsData,
};
