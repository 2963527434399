import { typedRoutes } from './utils/module-interfaces';

export const ROUTES = typedRoutes({
    FCRM: {
        id: 'OLD_WWISE_251',
        name: 'Team-Training',
        i18nId: 'NOT_NEEDED',
        path: `/SUPPRESS_DEFAULT_ROUTE`,
        displayInMenu: false,
    },
    EDUCATION: {
        id: 'EDUCATION',
        name: 'Old Education',
        i18nId: 'NOT_NEEDED',
        path: `/education`,
        redirectTo: `/learning`,
        displayInMenu: false,
    },
    ACCOUNT: {
        id: 'ACCOUNT',
        name: 'Old Account',
        i18nId: 'NOT_NEEDED',
        path: `/account`,
        redirectTo: `/settings/account-settings`,
        displayInMenu: false,
    },
    AMBASSADOR: {
        id: 'AMBASSADOR',
        name: 'Old Become Wwise Ambassador',
        i18nId: 'NOT_NEEDED',
        path: `/directory/ambassador-program/`,
        redirectTo: `/community/ambassador`,
        displayInMenu: false,
    },
    EDUCATION_LEARN_WWISE: {
        id: 'EDUCATION_LEARN_WWISE',
        name: 'Old Education learn-wwise',
        i18nId: 'NOT_NEEDED',
        path: `/education/learn-wwise`,
        redirectTo: `/learning/learn-wwise`,
        displayInMenu: false,
    },
    PRODUCTS_STRATA_TO_STRATA_OVERVIEW: {
        id: 'PRODUCTS_STRATA_TO_STRATA_OVERVIEW',
        name: 'PRODUCTS_STRATA_TO_STRATA_OVERVIEW',
        i18nId: 'NOT_NEEDED',
        path: `/products/strata`,
        redirectTo: `/strata/overview`,
        displayInMenu: false,
    },
    OLD_FCRM_IN_GAME_CREDITS: {
        id: 'OLD_FCRM_IN_GAME_CREDITS',
        name: 'Old In-game credits',
        i18nId: 'NOT_NEEDED',
        path: `/resources/credits/`,
        redirectTo: `/credits`,
        displayInMenu: false,
    },
    OLD_WWISE_101: {
        id: 'OLD_WWISE_251',
        name: 'Team-Training',
        i18nId: 'NOT_NEEDED',
        path: `/courses/wwise101`,
        redirectTo: `/learning/learn-wwise/wwise-fundamentals`,
        displayInMenu: false,
    },
    OLD_WWISE_201: {
        id: 'OLD_WWISE_251',
        name: 'Team-Training',
        i18nId: 'NOT_NEEDED',
        path: `/courses/wwise201`,
        redirectTo: `/learning/learn-wwise/interactive-music`,
        displayInMenu: false,
    },
    OLD_WWISE_251: {
        id: 'OLD_WWISE_251',
        name: 'Team-Training',
        i18nId: 'NOT_NEEDED',
        path: `/courses/wwise251`,
        redirectTo: `/learning/learn-wwise/wwise-performance-optimization`,
        displayInMenu: false,
    },
    OLD_WWISE_301: {
        id: 'OLD_WWISE_251',
        name: 'Team-Training',
        i18nId: 'NOT_NEEDED',
        path: `/courses/wwise301`,
        redirectTo: `/learning/learn-wwise/wwise-unity-integration`,
        displayInMenu: false,
    },
    OLD_TEAM_TRAINING: {
        id: 'OLD_TEAM_TRAINING',
        name: 'Team-Training',
        i18nId: 'NOT_NEEDED',
        path: `/learn/team-training`,
        redirectTo: `/learning/training`,
        displayInMenu: false,
    },
    OLD_PRODUCTS_SUPPORTED_PLATFORMS: {
        id: 'OLD_TEAM_TRAINING',
        name: 'Team-Training',
        i18nId: 'NOT_NEEDED',
        path: '/products/supported-platforms',
        redirectTo: `/wwise/supported-platforms`,
        displayInMenu: false,
    },
});

export const DEFAULT_ROUTE = ROUTES.FCRM;
export default ROUTES;
