import { HARD_PATHS } from '../utils/hard-paths';

const moduleRoot = '/products';

export const PATHS = {
    PRODUCTS: {
        path: `${moduleRoot}`,
    },
    WWISE: {
        path: `${moduleRoot}/wwise`,
    },
    // STRATA: {
    //     path: `${moduleRoot}/strata`,
    // },
    PLUGINS: {
        path: `${moduleRoot}/plugins`,
        hardPath: HARD_PATHS.PRODUCTS.PLUGINS,
    },
    WWISE_INTEGRATION: {
        path: `${moduleRoot}/wwise-integration`,
    },
    ADD_ONS: {
        path: `${moduleRoot}/add-ons`,
        hardPath: HARD_PATHS.PRODUCTS.ADD_ONS,
    },
    INTEGRATION: {
        path: `${moduleRoot}/integration`,
        hardPath: HARD_PATHS.PRODUCTS.WWISE_INTEGRATION,
    },
    STRATA_UPCOMING_COLLECTIONS: {
        path: `${moduleRoot}/strata#release_schedule`,
    },
    SUPPORTED_PLATFORMS: {
        path: `${moduleRoot}/supported-platforms`,
        hardPath: HARD_PATHS.PRODUCTS.SUPPORTED_PLATFORMS,
    },
    CREATE_ACCOUNT: {
        path: `${moduleRoot}/create-account`,
        hardPath: HARD_PATHS.PRODUCTS.CREATE_ACCOUNT,
    },
    REGISTER_COURSE: {
        path: `${moduleRoot}/register-course`,
        hardPath: HARD_PATHS.PRODUCTS.REGISTER_COURSE,
    },
    REGISTER_PROJECT: {
        path: `${moduleRoot}/register-project`,
        hardPath: HARD_PATHS.GENERAL.REGISTER_PROJECT,
    },
    REAPER: {
        path: `${moduleRoot}/reaper`,
        hardPath: HARD_PATHS.PRODUCTS.REAPER,
    },
    VST_PLUGIN: {
        path: `${moduleRoot}/vst-plugin`,
        hardPath: HARD_PATHS.PRODUCTS.VST_PLUGIN,
    },
    IEM_PLUGIN: {
        path: `${moduleRoot}/iem-plugin`,
        hardPath: HARD_PATHS.PRODUCTS.IEM_PLUGIN,
    },
};
